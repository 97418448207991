const EnableAudio = ({ onSuccess }) => {
	const enableSound = () => {
		if (onSuccess) {
			onSuccess();
		}
	};

	return (
		<div className="container enable-audio">
			<button
				type="button"
				className="btn btn-outline-primary"
				onClick={enableSound}
			>
				Enable Sound from Browser
			</button>
		</div>
	);
};

export default EnableAudio;
