import React from 'react';

const UnsupportedBrowser = () => {
	return (
		<div className="container">
			<h2 className="display-5">
				Unfortunately, this browser does not seem to be supported.
			</h2>
		</div>
	);
};

export default UnsupportedBrowser;
