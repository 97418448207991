import axios from 'axios';

const apiHost = 'https://chatsapi.realwebsite.com/';
// const apiHost = 'https://app.realwebsite.com/Services/';

export const GetChats = async (sessionId) => {
	let data = {};
	data.Url = window.location.origin;

	const response = await axios
		.get(apiHost + 'chats/list/' + sessionId, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	// console.log('response-data:', response, response.data.d);
	return response.data;
};
