import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainApp from './mainApp/MainApp';
import SessionGenerator from './sessionGenerator/SessionGenerator';

function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="/:sessionId" element={<MainApp />} />
				<Route path="/" element={<SessionGenerator />} />
			</Routes>
		</div>
	);
}

export default App;
