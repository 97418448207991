import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

const SessionGenerator = () => {
	const navigate = useNavigate();

	const generateSessionId = () => {
		const newId = uuid();

		navigate(`/${newId}`);
	};

	useEffect(() => {
		generateSessionId();
	}, []);

	return (
		<div>
			<div>Loading...</div>
		</div>
	);
};

export default SessionGenerator;
