import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetChats } from '../Services';
import Spinner from '../spinner/Spinner';
import chatSound from '../chat-sound.mp3';

const ChatMonitor = () => {
	const { sessionId } = useParams();
	const [ isLoading, setIsLoading ] = useState(false);
	const chatAudioRef = useRef();

	useEffect(() => {
		getCurrentChats();

		let chatCheckInterval = setInterval(() => {
			getCurrentChats();
		}, 2000);

		return () => {
			clearInterval(chatCheckInterval);
		};
	}, []);

	const getCurrentChats = async () => {
		setIsLoading(true);

		const response = await GetChats(sessionId);

		if (response && response.length > 0) {
			playChatNotification();

			for (let i = 0; i < response.length; i++) {
				// create a notification for each chat
				const chat = response[i];

				const img = `/img/${chat.source}.svg`;
				let text = `You have a new chat on "${chat.source}"`;

				if (chat.name) {
					text += ` from ${chat.name}`;
				}

				if (chat.email) {
					text += ` (${chat.email})`;
				}

				const notification = new Notification('LiveChat', {
					body : text,
					icon : img
				});
			}
		}

		console.log(response);

		setIsLoading(false);
	};

	const playChatNotification = async () => {
		chatAudioRef.current.play();
	};

	return (
		<div className="container chat-monitor">
			<div className="mb-3">
				<small className="text-muted">{`Monitoring chats under session id: ${sessionId}`}</small>
			</div>

			{isLoading && <Spinner />}

			<audio
				ref={chatAudioRef}
				src={chatSound}
				autoplay="false"
				autostart="false"
				preload="none"
				name="media"
			/>
		</div>
	);
};

export default ChatMonitor;
