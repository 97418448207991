import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChatMonitor from '../chatMonitor/ChatMonitor';
import EnableAudio from '../enableAudio/EnableAudio';
import GetPermissions from '../getPermissions/GetPermissions';

const MainApp = () => {
	const { sessionId } = useParams();
	const [ isLoading, setIsLoading ] = useState(true);
	const [
		hasNotificationPermissions,
		setHasNotificationPermissions
	] = useState(false);
	const [ enabledAudio, setEnabledAudio ] = useState(false);

	const handlePermissionsSuccess = () => {
		setHasNotificationPermissions(true);
	};

	if (!hasNotificationPermissions) {
		return <GetPermissions onSuccess={handlePermissionsSuccess} />;
	}

	if (!enabledAudio) {
		return <EnableAudio onSuccess={() => setEnabledAudio(true)} />;
	}

	return (
		<div className="main-app">
			<ChatMonitor />
		</div>
	);
};

export default MainApp;
