import { Fragment, useEffect, useState } from 'react';
import UnsupportedBrowser from '../unsupportedBrowser/UnsupportedBrowser';

const GetPermissions = ({ onSuccess }) => {
	const [
		hasNotificationPermissions,
		setHasNotificationPermissions
	] = useState(false);
	const [ rejectedPermission, setRejectedPermission ] = useState(false);
	const [ unsupportedBrowser, setUnsupportedBrowser ] = useState(false);

	useEffect(() => {
		getPermissions();
	}, []);

	const getPermissions = () => {
		// Let's check if the browser supports notifications
		if (!('Notification' in window)) {
			console.log('This browser does not support notifications.');
			setUnsupportedBrowser(true);
		} else if (checkNotificationPromise()) {
			Notification.requestPermission().then((permission) => {
				handlePermissions(permission);
			});
		} else {
			Notification.requestPermission((permission) => {
				handlePermissions(permission);
			});
		}
	};

	const checkNotificationPromise = () => {
		try {
			Notification.requestPermission().then();
		} catch (e) {
			return false;
		}

		return true;
	};

	const handlePermissions = (permission) => {
		switch (permission) {
			case 'granted':
				setHasNotificationPermissions(true);
				if (onSuccess) {
					onSuccess();
				}
				break;
			case 'block':
				setHasNotificationPermissions(false);
				setRejectedPermission(true);
				break;
			case 'none':
				setHasNotificationPermissions(false);
				setRejectedPermission(true);
				break;
			default:
				break;
		}
	};

	if (unsupportedBrowser) {
		return <UnsupportedBrowser />;
	}

	return (
		<div className="container permissions-check">
			{!rejectedPermission ? (
				<Fragment>
					<h5 className="display-5">
						Enable Browser Notification permissions in order to use
						this app.
					</h5>
					<button
						type="button"
						className="btn btn-outline-primary"
						onClick={getPermissions}
					>
						Enable Notification Permissions
					</button>
				</Fragment>
			) : (
				<Fragment>
					<h5 className="display-5">
						Looks like you rejected the notifications permission. In
						order to use this app the notifications have to be
						enabled.
					</h5>
					<button
						type="button"
						className="btn btn-outline-primary"
						onClick={getPermissions}
					>
						Enable Notification Permissions
					</button>
				</Fragment>
			)}
		</div>
	);
};

export default GetPermissions;
